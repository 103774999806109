<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
          <div class="d-lg-flex align-items-center justify-content-between">
            <!-- Content -->
            <div class="mb-4 mb-lg-0">
              <h1 class="text-white mb-1">Sửa khóa học</h1>
              <p class="mb-0 text-white lead">
                Điền thông tin rồi ấn Cập nhật để cập nhật khóa học
              </p>
            </div>
            <div>
              <router-link class="btn btn-white" :to="{name: 'MySchool', query: {tab: 'course'}}">Danh sách khóa học
              </router-link>&nbsp;
              <button type="button" :disabled="updateCourseDisabled" v-on:click="onSubmitCourse"
                      class="btn btn-success ">Lưu lại
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-12">
    <div class="container">
      <div id="courseForm" class="bs-stepper">
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
            <!-- Stepper Button -->
            <div class="bs-stepper-header shadow-sm" role="tablist">
              <div class="step" data-target="#tab1_content">
                <button type="button" class="step-trigger" role="tab" id="tab1" aria-controls="tab1_content">
                  <span class="bs-stepper-circle">1</span>
                  <span class="bs-stepper-label">Giới thiệu</span>
                </button>
              </div>
              <div class="bs-stepper-line"></div>
              <div class="step" data-target="#tab2_content">
                <button type="button" class="step-trigger" role="tab" id="tab2" aria-controls="tab2_content">
                  <span class="bs-stepper-circle">2</span>
                  <span class="bs-stepper-label">Bài học</span>
                </button>
              </div>
              <div class="bs-stepper-line"></div>
              <div class="step" data-target="#tab3_content">
                <button type="button" class="step-trigger" role="tab" id="tab3" aria-controls="tab3_content">
                  <span class="bs-stepper-circle">3</span>
                  <span class="bs-stepper-label">Cài đặt</span>
                </button>
              </div>
            </div>
            <!-- Stepper content -->
            <div class="bs-stepper-content mt-5">
              <form v-on:submit.prevent="onSubmitCourse">

                <div id="tab1_content" role="tabpanel" class="bs-stepper-pane fade" aria-labelledby="tab1">
                  <!-- Card -->
                  <div class="card mb-3 ">
                    <div class="card-header border-bottom px-4 py-3">
                      <h4 class="mb-0">Giới thiệu</h4>
                    </div>
                    <!-- Card body -->
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="mb-3">
                            <label for="name" class="form-label">Tên khóa học</label>
                            <input v-model="course.name" id="name" class="form-control" type="text" required/>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Giảng viên</label>
                            <Select2 v-model="course.teachers" name="name"
                                     :settings="{ multiple: true, templateSelection: (item) => {return item.name}, templateResult: (item) => {return item.name} }"
                                     :options="this.teachers"/>
                          </div>
                          <div class="mb-3 mt-3">
                            <label class="form-label">Trạng thái</label>
                            <div>
                              <div class="d-inline-flex">
                                <div class="form-check me-3">
                                  <input type="radio" id="status1" v-model="course.status" value=1
                                         class="form-check-input">
                                  <label class="form-check-label" for="status1">Mở bán</label>
                                </div>
                                <div class="form-check me-3">
                                  <input type="radio" id="status0" v-model="course.status" value=0
                                         class="form-check-input">
                                  <label class="form-check-label" for="status0">Ngừng bán</label>
                                </div>
                                <div class="form-check">
                                  <input type="radio" id="status2" v-model="course.status" value=2
                                         class="form-check-input">
                                  <label class="form-check-label" for="status2">Nháp</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Hiện lên trang chủ?</label>
                            <div>
                              <div class="d-inline-flex">
                                <div class="form-check me-3">
                                  <input type="radio" id="showHomepage1" v-model="course.show_homepage" value=1
                                         class="form-check-input">
                                  <label class="form-check-label" for="showHomepage1">Hiện</label>
                                </div>
                                <div class="form-check me-3">
                                  <input type="radio" id="showHomepage0" v-model="course.show_homepage" value=0
                                         class="form-check-input">
                                  <label class="form-check-label" for="showHomepage0">Không hiện</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label" for="homepage_order">Vị trí trên trang chủ?</label>
                            <input v-model="course.homepage_order" id="homepage_order" class="form-control"
                                   type="number"/>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Welcome khóa học</label>
                            <Editor v-model:content="course.welcome"/>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Lợi ích của khóa học</label>
                            <Editor v-model:content="course.benefit"/>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Giới thiệu khóa học</label>
                            <Editor v-model:content="course.intro"/>
                          </div>
                          <div class="mb-3 mt-3">
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="fee_type_0" v-model="course.fee_type" value=0
                                       class="form-check-input">
                                <label class="form-check-label" for="fee_type_0">Miễn phí</label>
                              </div>
                              <div class="form-check me-3">
                                <input type="radio" id="fee_type_1" v-model="course.fee_type" value=1
                                       class="form-check-input">
                                <label class="form-check-label" for="fee_type_1">Trả phí</label>
                              </div>
                              <div class="form-check">
                                <input type="radio" id="fee_type_2" v-model="course.fee_type" value=2
                                       class="form-check-input">
                                <label class="form-check-label" for="fee_type_2">Không bán lẻ (Bán combo hoặc
                                  membership)</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <Thumbnail :thumbnail="course.thumbnail" purpose="setThumbnailCourse"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="tab2_content" role="tabpanel" class="bs-stepper-pane fade" aria-labelledby="tab2">
                  <!-- Card -->
                  <div class="card mb-3  border-0">
                    <div class="card-header border-bottom px-4 py-3">
                      <h4 class="mb-0">Nội dung bài học</h4>
                    </div>
                    <!-- Card body -->
                    <div class="card-body">
                      <draggable
                          v-model="course.sections"
                          group="people"
                          @start="drag=true"
                          @end="drag=false"
                          item-key="id">
                        <template #item="{element, index}">
                          <div class="flex items-center justify-between card px-3 mb-3" style="cursor: move">
                            <Section v-on:setCurrentSection="setCurrentSection"
                               v-on:insertAttachmentForLesson="insertAttachmentForLesson"
                               v-on:prepareAddingLesson="prepareAddingLesson"
                               v-on:setCurrentLesson="setCurrentLesson"
                               v-on:updateLessonsForSection="updateLessonsForSection"
                               :section-index="index + ''"
                               :section="element"/>
                          </div>
                        </template>
                      </draggable>

                      <a class="btn btn-primary btn-sm" v-on:click="this.prepareAddingSection" data-bs-toggle="modal"
                         data-bs-target="#addSectionModal">Thêm chương +</a>
                    </div>
                  </div>
                </div>

                <div id="tab3_content" role="tabpanel" class="bs-stepper-pane fade" aria-labelledby="tab3">
                  <!-- Card -->
                  <div class="card mb-3  border-0">
                    <div class="card-header border-bottom px-4 py-3">
                      <h4 class="mb-0">Cài đặt</h4>
                    </div>
                    <!-- Card body -->
                    <div class="card-body">
                      <div class="mb-3">
                        <p class="form-label">Điều kiện học khóa học</p>
                        <div class="">
                          <div class="form-check me-3">
                            <input type="radio" id="condition_course_0" v-model="course.condition_course" value=0
                                   class="form-check-input" checked>
                            <label class="form-check-label" for="condition_course_0">Luôn mở</label>
                          </div>
                          <div class="form-check me-3">
                            <input type="radio" id="condition_course_1" v-model="course.condition_course" value=1
                                   class="form-check-input">
                            <label class="form-check-label" for="condition_course_1">Hoàn thành khóa học trước</label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <p class="form-label">Điều kiện mở bài học</p>
                        <div class="">
                          <div class="form-check me-3">
                            <input type="radio" id="condition_lesson_0" v-model="course.condition_lesson" value=0
                                   class="form-check-input" checked>
                            <label class="form-check-label " for="condition_lesson_0">Mở tất cả bài học</label>
                          </div>
                          <div class="form-check me-3">
                            <input type="radio" id="condition_lesson_1" v-model="course.condition_lesson" value=1
                                   class="form-check-input">
                            <label class="form-check-label" for="condition_lesson_1">Mở từng bài học sau x ngày</label>
                          </div>
                          <div class="form-check me-3">
                            <input type="radio" id="condition_lesson_2" v-model="course.condition_lesson" value=2
                                   class="form-check-input">
                            <label class="form-check-label" for="condition_lesson_2">Mở bài học tiếp theo sau khi hoàn
                              thành bài trước đó sau x ngày</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editSectionTitleModal" tabindex="-1" role="dialog" aria-labelledby="editSectionTitleLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editSectionTitleLabel">
            Tiêu đề chương
          </h4>
          <button type="button" id="editSectionTitleModalClose" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="updateSectionTitle">
            <input class="form-control mb-3" ref="sectionTitle" type="text" v-model="currentSectionTitle"/>
            <div class="d-grid gap-2">
              <button class="btn btn-primary" type="submit">
                Cập nhật
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addSectionModal" tabindex="-1" role="dialog" aria-labelledby="addSectionModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="addSectionModalLabel">
            Tiêu đề chương
          </h4>
          <button type="button" id="addSectionModalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addSection">
            <input class="form-control mb-3" ref="newSectionTitle" type="text" v-model="newSectionTitle"/>
            <div class="d-grid gap-2">
              <button class="btn btn-primary" type="submit">
                Thêm mới
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addLessonModal" tabindex="-1" role="dialog" aria-labelledby="addLessonModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="addLessonModalLabel">
            Tiêu đề bài học
          </h4>
          <button type="button" id="addLessonModalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addLesson">
            <input class="form-control mb-3" ref="newLessonTitle" type="text" v-model="newLessonTitle"/>
            <div class="d-grid gap-2">
              <button class="btn btn-primary" type="submit">
                Thêm mới
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editLessonTitleModal" tabindex="-1" role="dialog" aria-labelledby="editLessonTitleLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="editLessonTitleLabel">
            Tiêu đề bài học
          </h4>
          <button type="button" id="editLessonTitleModalClose" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="updateLessonTitle">
            <input class="form-control mb-3" type="text" v-model="currentLessonTitle"/>
            <div class="d-grid gap-2">
              <button class="btn btn-primary" type="submit">
                Cập nhật
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeSectionModal" tabindex="-1" role="dialog" aria-labelledby="removeSectionModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="removeSectionModalLabel">
            Xóa chương: {{ currentSection.name }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc chắn muốn xóa chương này?</p>
          <div class="d-grid gap-2">
            <button class="btn btn-danger" data-bs-dismiss="modal" type="Button" v-on:click="removeSection">
              Xóa chương
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeLessonModal" tabindex="-1" role="dialog" aria-labelledby="removeLessonModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="removeLessonModalLabel">
            Xóa bài: {{ currentLesson.name }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc chắn muốn xóa bài này?</p>
          <div class="d-grid gap-2">
            <button class="btn btn-danger" data-bs-dismiss="modal" type="Button" v-on:click="removeLesson">
              Xóa bài
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Media :show-courseware="true" :mediaMode="mediaMode" v-on:setMedia="handleSetMediaEvent" :purpose="mediaPurpose"
         :mediaType="mediaType"/>

  <Modal size="modal-md" id="updateCourseModal" title="Cập nhật khóa học" :message="updateCourseMessage"
         :type="updateCourseType"/>
</template>

<script>
import Section from "@/components/Section";
import Editor from "@/components/Editor";
import Thumbnail from "@/components/Thumbnail";
import Media from "@/components/Media";
import ApiService from "@/core/services/api.service";
import Select2 from 'vue3-select2-component';
import Modal from "@/components/Modal";
import {getSectionLessonCount} from "@/core/services/utils.service";
import draggable from 'vuedraggable';

export default {
  name: 'AdminCourseAdd',
  components: {
    Section,
    Editor,
    Thumbnail,
    Media,
    Select2,
    draggable,
    Modal
  },
  data() {
    return {
      course: {
        name: "",
        status: 1,
        show_homepage: 0,
        homepage_order: 0,
        welcome: "",
        intro: "",
        benefit: "",
        fee_type: 0,
        condition_course: 0,
        condition_lesson: 0,
        thumbnail: {
          id: 0
        },
        sections: [],
        teachers: [],
        school: {
          code: " "
        }
      },
      teachers: [],
      courseForm: null,
      currentLessonIndex: null,
      currentLesson: {name: "", attachments: []},
      currentSectionIndex: null,
      currentSection: {name: ""},
      currentSectionTitle: "",
      newSectionTitle: "",
      currentLessonTitle: "",
      newLessonTitle: "",
      mediaMode: "default",
      mediaType: "",
      mediaPurpose: "setThumbnailForCourse",
      originalSectionIDs: {},
      apiURL: process.env.VUE_APP_API_URL,
      updateCourseType: "",
      updateCourseDisabled: false,
      updateCourseMessage: ""
    }
  },
  methods: {
    setCurrentSection(index, section) {
      console.log("setCurrentSection...", index, section);
      this.currentSectionIndex = index;
      this.currentSection = section;
      this.currentSectionTitle = section.name;
    },
    prepareAddingSection() {
      this.newSectionTitle = "";
      this.$refs.newSectionTitle.focus();
    },
    addSection() {
      let section = this.getSampleSection(this.newSectionTitle);
      this.course.sections.push(section);
      window.$("#addSectionModalClose").click();
    },
    removeSection() {
      this.course.sections.splice(this.currentSectionIndex, 1);
    },
    setCurrentLesson(index, lesson, sectionIndex) {
      this.currentLessonIndex = index;
      this.currentLesson = lesson;
      this.currentSectionIndex = sectionIndex;
      this.currentLessonTitle = lesson.name;
    },
    updateLessonsForSection(lessons, sectionIndex) {
      for (let i = 0; i < this.course.sections.length; i++) {
        if (i === sectionIndex) {
          let section = this.course.sections[i];
          section.lessons = lessons;
          return;
        }
      }
    },
    insertAttachmentForLesson(index, lesson, mediaType, sectionIndex) {
      this.currentLessonIndex = index;
      this.currentLesson = lesson;
      this.currentSectionIndex = sectionIndex;
      this.mediaPurpose = "insertAttachmentForLesson";
      this.mediaMode = "insert";
      this.mediaType = mediaType;
    },
    prepareAddingLesson(index) {
      this.currentSectionIndex = parseInt(index);
      this.newLessonTitle = "";
    },
    addLesson() {
      for (let i = 0; i < this.course.sections.length; i++) {
        if (i === this.currentSectionIndex) {
          let section = this.course.sections[i];
          let lesson = this.getSampleLesson(this.newLessonTitle);
          section.lessons.push(lesson);
          window.$("#addLessonModalClose").click();
          return;
        }
      }
    },
    removeLesson() {
      let index = parseInt(this.currentSectionIndex);
      let lessonIndex = parseInt(this.currentLessonIndex);
      for (let i = 0; i < this.course.sections.length; i++) {
        if (i === index) {
          let section = this.course.sections[i];
          for (let j = 0; j < section.lessons.length; j++) {
            if (j === lessonIndex) {
              section.lessons.splice(j, 1);
              return;
            }
          }
        }
      }
    },
    updateSectionTitle() {
      this.currentSection.name = this.currentSectionTitle;
      window.$("#editSectionTitleModalClose").click();
    },
    updateLessonTitle() {
      this.currentLesson.name = this.currentLessonTitle;
      window.$("#editLessonTitleModalClose").click();
    },
    getSampleSection(name) {
      return {
        name: name,
        lessons: []
      }
    },
    getSampleLesson(name) {
      return {
        name: name,
        attachments: []
      }
    },
    getSectionQuery(sections, diffLessonIDs) {
      let queries = [];
      let variables = {};
      let types = {};

      for (let i = 0; i < sections.length; i++) {
        let id = `$id_${i}`;
        let name = `$name_${i}`;
        let position = `$position_${i}`;
        let deleteLessonIDs = `$deleteLessonIDs_${i}`;
        let lessonQuery = this.getLessonQuery(sections[i].lessons, i);
        let sectionId = typeof sections[i].id !== "undefined" ? sections[i].id : 0;

        queries.push(`{
          id: ${id},
          name: ${name},
          position: ${position},
          lessons: {
            upsert: [
              ${lessonQuery.query}
            ]
            delete: ${deleteLessonIDs}
          }
        }`);

        variables[`id_${i}`] = sectionId;
        variables[`name_${i}`] = sections[i].name;
        variables[`position_${i}`] = i;

        let deleteLessonIDsValue = [];
        if (typeof diffLessonIDs[sectionId] !== "undefined") {
          deleteLessonIDsValue = diffLessonIDs[sectionId];
        }
        variables[`deleteLessonIDs_${i}`] = deleteLessonIDsValue;

        types[id] = "ID";
        types[name] = "String!";
        types[position] = "Int";
        types[deleteLessonIDs] = "[ID]";

        variables = {...variables, ...lessonQuery.variables};
        types = {...types, ...lessonQuery.types};
      }

      return {
        query: queries.join(","),
        variables: variables,
        types: types
      }
    },
    getAttachmentSync(attachments) {
      let sync = [];

      for (let i = 0; i < attachments.length; i++) {
        sync.push({id: attachments[i].id, is_courseware: parseInt(attachments[i].attachmentPivot.is_courseware)});
      }

      return sync;
    },
    getLessonQuery(lessons, sectionIndex) {
      let queries = [];
      let variables = {};
      let types = {};

      for (let i = 0; i < lessons.length; i++) {
        let sync = this.getAttachmentSync(lessons[i].attachments);
        let id = `$id_${sectionIndex}_${i}`;
        let name = `$name_${sectionIndex}_${i}`;
        let content = `$content_${sectionIndex}_${i}`;
        let position = `$position_${sectionIndex}_${i}`;
        let attachments = `$attachments_${sectionIndex}_${i}`;

        queries.push(`{
          id: ${id}
          name: ${name}
          content: ${content}
          position: ${position}
          attachments: {
            sync: ${attachments}
          }
        }`);

        variables[`id_${sectionIndex}_${i}`] = typeof lessons[i].id !== "undefined" ? lessons[i].id : 0;
        variables[`name_${sectionIndex}_${i}`] = lessons[i].name;
        variables[`content_${sectionIndex}_${i}`] = lessons[i].content;
        variables[`position_${sectionIndex}_${i}`] = i;
        variables[`attachments_${sectionIndex}_${i}`] = sync;

        types[id] = "ID";
        types[name] = "String!";
        types[content] = "String";
        types[position] = "Int";
        types[attachments] = "[AttachmentInput]";
      }

      return {
        query: queries.join(","),
        variables: variables,
        types: types
      }
    },
    getSubmitQuery() {
      let newSectionIDs = this.getCurrentSectionIDs(this.course.sections);
      let deleteIDs = this.getDeleteSectionIDs(this.originalSectionIDs, newSectionIDs);
      let courseCount = getSectionLessonCount(this.course);

      let variables = {
        id: this.course.id,
        name: this.course.name,
        status: parseInt(this.course.status),
        show_homepage: parseInt(this.course.show_homepage),
        homepage_order: parseInt(this.course.homepage_order),
        section_count: courseCount.sectionCount,
        lesson_count: courseCount.lessonCount,
        fee_type: parseInt(this.course.fee_type),
        thumbnail_id: this.course.thumbnail ? parseInt(this.course.thumbnail.id) : 0,
        intro: this.course.intro,
        benefit: this.course.benefit,
        welcome: this.course.welcome,
        condition_course: parseInt(this.course.condition_course),
        condition_lesson: parseInt(this.course.condition_lesson),
        teachers: this.course.teachers,
        deleteSectionIDs: deleteIDs.deleteSectionIDs
      };

      let types = {
        $id: "ID!",
        $name: "String!",
        $status: "Int",
        $show_homepage: "Int",
        $homepage_order: "Int",
        $section_count: "Int",
        $lesson_count: "Int",
        $fee_type: "Int",
        $thumbnail_id: "Int",
        $intro: "String",
        $benefit: "String",
        $welcome: "String",
        $condition_course: "Int",
        $condition_lesson: "Int",
        $teachers: "[ID]",
        $deleteSectionIDs: "[ID]"
      }

      let sectionQuery = this.getSectionQuery(this.course.sections, deleteIDs.diffLessonIDs);
      variables = {...variables, ...sectionQuery.variables};
      types = {...types, ...sectionQuery.types};
      let typesJoined = [];
      for (let key in types) {
        // eslint-disable-next-line no-prototype-builtins
        if (types.hasOwnProperty(key)) {
          typesJoined.push(`${key}: ${types[key]}`);
        }
      }

      let query = `mutation(${typesJoined.join(", ")}) {
        updateCourse(input: {
          id: $id
          name: $name
          status: $status
          show_homepage: $show_homepage
          homepage_order: $homepage_order
          section_count: $section_count
          lesson_count: $lesson_count
          fee_type: $fee_type
          thumbnail_id: $thumbnail_id
          intro: $intro
          benefit: $benefit
          welcome: $welcome
          condition_course: $condition_course
          condition_lesson: $condition_lesson
          sections: {
            upsert: [
              ${sectionQuery.query}
            ],
            delete: $deleteSectionIDs
          },
          teachers: {
            sync: $teachers
          }
        }) {
          code
        }
      }`;

      return {
        query: query,
        variables: variables
      };
    },
    onSubmitCourse() {
      this.updateCourseDisabled = true;
      let submitQuery = this.getSubmitQuery();

      ApiService.graphql(submitQuery.query, submitQuery.variables)
          .then(({data}) => {
            this.updateCourseDisabled = false;
            if (data.data && data.data.updateCourse) {
              this.updateCourseType = "success";
              this.updateCourseMessage = "Cập nhật thành công";
              window.$("#updateCourseModalOpen").click();
            } else {
              this.updateCourseType = "danger";
              this.updateCourseMessage = "Cập nhật thất bại";
              window.$("#updateCourseModalOpen").click();
            }
          })
          .catch((response) => {
            this.updateCourseDisabled = false;
            this.updateCourseType = "danger";
            this.updateCourseMessage = response.message;
            window.$("#updateCourseModalOpen").click();
          });
    },
    loadCourse() {
      let query = `query {
        course(code:"${this.$route.params.code}") {
          id
          code
          name
          status
          show_homepage
          homepage_order
          fee_type
          intro
          welcome
          benefit
          thumbnail_id
          condition_course
          condition_lesson
          sections(orderBy: [{column: "position", order: ASC}]) {
            id
            name
            position
            lessons(orderBy: [{column: "position", order: ASC}]) {
              id
              name
              content
              attachments {
                id
                uid
                name
                link
                media_type
                attachmentPivot {
                  is_courseware
                }
              }
            }
          }
          thumbnail {
            id
            link
            media_type
          }
          teachers {
            id
          }
          school {
            code
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (typeof data.data.course !== "undefined") {
              this.course = data.data.course;
              this.course.teachers = this.getSelectedTeachers();
              this.originalSectionIDs = this.getCurrentSectionIDs(this.course.sections);
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    getCurrentSectionIDs(sections) {
      let ids = {};

      for (let i = 0; i < sections.length; i++) {
        if (typeof sections[i].id !== "undefined") {
          ids[sections[i].id] = [];
          for (let j = 0; j < sections[i].lessons.length; j++) {
            if (typeof sections[i].lessons[j].id !== "undefined") {
              ids[sections[i].id].push(parseInt(sections[i].lessons[j].id));
            }
          }
        }
      }

      return ids;
    },
    getDeleteSectionIDs(listOld, listNew) {
      let deleteSectionIDs = [];
      let diffLessonIDs = {};
      for (let id in listOld) {
        // eslint-disable-next-line no-prototype-builtins
        if (listOld.hasOwnProperty(id)) {
          if (typeof listNew[id] === "undefined") {
            deleteSectionIDs.push(id);
          } else {
            let diff = listOld[id].filter(x => !listNew[id].includes(x));
            if (diff.length) {
              diffLessonIDs[id] = diff;
            }
          }
        }
      }

      return {
        deleteSectionIDs: deleteSectionIDs,
        diffLessonIDs: diffLessonIDs
      }
    },
    loadTeachers() {
      let query = `query {
        teachers {
          id
          name
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.teachers) {
              this.teachers = data.data.teachers;
            }
          });
    },
    getSelectedTeachers() {
      let ids = [];
      for (let i = 0; i < this.course.teachers.length; i++) {
        ids.push(parseInt(this.course.teachers[i].id));
      }
      return ids;
    },
    handleSetMediaEvent(media, purpose) {
      if (purpose === "setThumbnailCourse") {
        this.course.thumbnail = media;
      } else if (purpose === "insertAttachmentForLesson") {
        this.currentLesson.attachments.push(media);
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.courseForm = new Stepper(document.querySelector("#courseForm"), {linear: !1, animation: !0})
    this.loadCourse();
    this.loadTeachers();
  }
}
</script>
